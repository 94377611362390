<template>
  <div class="w-full flex justify-center items-start mb-16">
    <Modal id="confirmationModal" v-if="showConfirm">
      <h1 class="text-blue-800 text-3xl font-bold my-3">
        Confirmación de carga de recomendación
      </h1>
      <p class="my-8">
        Por favor tener en cuenta una vez se carga la recomendacion, la
        informacion generada en el formulario
        <b>NO PODRA SER MODIFICADA O EDITADA</b>, si aun debe confirmar datos,
        se sugiere guardar como borrador la recomendacion.<br /><br />¿Esta
        seguro que desea cargar la recomendacion creada?
      </p>
      <div class="flex gap-3 justify-center my-3x capitalize">
        <BaseButton @click.native="closeModal" label="CANCELAR" danger="true" />
        <BaseButton
          @click.native="saveRecommendationConfirm"
          label="CARGAR RECOMENDACIÓN"
        /></div
    ></Modal>
    <StepOne
      v-if="stepCount === 1"
      :initialData="dataForm"
      @saveChanges="saveChanges"
    />
    <StepTwo
      v-if="stepCount === 2"
      :initialData="dataForm"
      @saveChanges="saveChanges"
      @backStep="backStep"
    />
    <StepThree
      v-if="stepCount === 3"
      :initialData="dataForm"
      @saveRecommendation="saveRecommendation"
      @backStep="backStep"
    />
  </div>
</template>

<script>
import StepOne from "@/components/Dashboard/FCO/Recommendations/NewRecommendationSteps/StepOne.vue";
import StepTwo from "@/components/Dashboard/FCO/Recommendations/NewRecommendationSteps/StepTwo.vue";
import StepThree from "@/components/Dashboard/FCO/Recommendations/NewRecommendationSteps/StepThree.vue";

export default {
  components: {
    StepOne,
    StepTwo,
    StepThree,
  },
  data() {
    return {
      dataForm: {},
      stepCount: 1,
      showConfirm: false,
    };
  },
  mounted() {
    // Obtener los datos almacenados en localStorage
    const savedData = localStorage.getItem("newRecommendationFormSave");

    if (savedData) {
      try {
        // Intentar parsear el JSON
        const parsedData = JSON.parse(savedData);

        if (typeof parsedData === "object") {
          console.log(
            "Objeto JSON válido encontrado en localStorage:",
            parsedData
          );
          this.dataForm = {
            ...this.dataForm,
            ...parsedData,
          };
        } else {
          console.log(
            "El contenido en localStorage no es un objeto JSON válido."
          );
        }
      } catch (error) {
        console.log(
          "Error al analizar el JSON almacenado en localStorage:",
          error
        );
      }
    } else {
      console.log("No se encontraron datos en localStorage.");
    }
  },
  methods: {
    closeModal() {
      this.showConfirm = false;
    },
    saveChanges(objPivot = {}) {
      this.dataForm = {
        ...this.dataForm,
        ...objPivot,
      };
      this.stepCount = this.stepCount + 1;
    },
    saveRecommendation(objPivot = {}) {
      this.showConfirm = true;
      this.dataForm = {
        ...this.dataForm,
        ...objPivot,
      };
    },
    saveRecommendationConfirm() {
      localStorage.removeItem("newRecommendationFormSave");
      // aca va el evento submit
      this.$router.push("recommendations?success=true");
    },
    backStep() {
      this.stepCount = this.stepCount - 1;
    },
  },
  computed: {},
};
</script>

<style>
.simpleCard {
  margin-right: auto;
  max-width: 1020px;
  border: 1px solid #ddd6d6;
  border-radius: 10px;
  padding: 30px;
}
.w-50 {
  width: 50%;
}
.customTitle {
  font-size: 1.5em;
}
.customButtons {
  width: 80%;
  margin-left: 10%;
}
</style>
