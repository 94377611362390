<template>
  <div class="w-full mb-2 simpleCard">
    <h2 class="text-blue text-left customTitle">
      <b>Nueva Recomendación</b>
    </h2>
    <div class="w-full w-50">
      <BaseSelect
        ref="targetEntity"
        id="targetEntity"
        label="Selecciona entidad vigilada"
        :selectOptions="optionsCompanies"
        v-model="targetEntity"
        @input="resetTargetEntity()"
      />
    </div>
    <div class="w-full w-50">
      <BaseRadio
        name="typeRecommendation"
        id="typeRecommendation"
        label="Tipo de recomendación"
        :radioOptions="baseRadioOptionTypeRecommendation"
        v-model="dataForm.typeRecommendation"
      />
    </div>
    <div class="w-full flex flex-row gap-3">
      <BaseInput
        v-if="dataForm.typeRecommendation !== 'Histórica'"
        subtitle="Año vencido"
        type="date"
        label="Fecha de formulación de la recomendación"
        placeholder=""
        v-model="dataForm.expiredYear"
      />
      <BaseInput
        v-if="dataForm.typeRecommendation !== 'Histórica'"
        subtitle="Año actual"
        type="date"
        label="Fecha de formulación de la recomendación"
        placeholder=""
        v-model="dataForm.currentYear"
      />
      <BaseInput
        v-if="dataForm.typeRecommendation === 'Histórica'"
        subtitle="La fecha de formulación debe ser en un periodo anterior al año vencido"
        type="date"
        label="Fecha de formulación de la recomendación"
        placeholder=""
        v-model="dataForm.historicalYear"
      />
    </div>
    <div class="form-group w-full mt-5 text-left flex flex-col customButtons">
      <div class="flex flex-row gap-3">
        <a-button
          type="primary"
          shape="round"
          :block="true"
          ghost
          @click="cancelProcess"
          >Cancelar</a-button
        >
        <a-button
          type="primary"
          shape="round"
          :block="true"
          @click="saveAndNextStep"
          >Siguiente</a-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["initialData"],
  data() {
    return {
      targetEntity: this.initialData?.targetEntity || "",
      dataForm: {
        ...this.initialData,
      },
      baseRadioOptionTypeRecommendation: [
        { id: "Nueva", name: "Nueva" },
        { id: "Histórica", name: "Histórica" },
      ],
    };
  },
  methods: {
    resetTargetEntity() {
      this.dataForm.targetEntity = null;
    },
    saveAndNextStep() {
      this.$emit("saveChanges", {
        ...this.dataForm,
        targetEntity: this.targetEntity,
      });
    },
    cancelProcess() {
      this.$router.push("recommendations");
    },
  },
  computed: {
    optionsCompanies: function () {
      return this.$store.state.form?.optionsCompanies?.filter(
        (item) => item?.direction == this.$store.state?.session?.user.direction
      );
    },
  },
};
</script>

<style>
.simpleCard {
  margin-right: auto;
  max-width: 1020px;
  border: 1px solid #ddd6d6;
  border-radius: 10px;
  padding: 30px;
}
.w-50 {
  width: 50%;
}
.customTitle {
  font-size: 1.5em;
}
.customButtons {
  width: 80%;
  margin-left: 10%;
}
</style>
