var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full mb-2 simpleCard"},[(_vm.visibleButtonSaveTemp)?_c('a-button',{staticClass:"btn-save-temporaly",attrs:{"shape":"round","type":"primary"},on:{"click":_vm.saveTemporaly}},[_vm._v(_vm._s(_vm.msgButtonTemp))]):_vm._e(),_vm._m(0),_c('Accordion',{attrs:{"header":"2. Formulación de la recomendación del DCF","open":""}},[_c('div',{staticClass:"mb-2"},[_c('BaseTextarea',{attrs:{"styles":"mt-0","type":"text","id":"formulationOfTheDcfRecommendation","label":"Descripción especifica de la recomendación formulada, tendiente a prevenir el impacto de los hechos o situaciones que puedan afectar a los consumidores financieros de la EV, propendiendo por el trato justo en el marco de la adecuada prestación del servicio y seguridad en el desarrollo de las actividades y en las relaciones entre esta y sus clientes, usuarios o potenciales clientes.","maxlength":"1000"},model:{value:(_vm.dataForm.formulationOfTheDcfRecommendation),callback:function ($$v) {_vm.$set(_vm.dataForm, "formulationOfTheDcfRecommendation", $$v)},expression:"dataForm.formulationOfTheDcfRecommendation"}})],1),_c('div',{staticClass:"w-full w-50 mb-2"},[_c('BaseRadio',{attrs:{"name":"formalPresentation","id":"formalPresentation","label":"¿La DCF presento formalmente la recomendación a la EV a traves de los canales establecidos?","radioOptions":[
          { id: 'Si', name: 'Si' },
          { id: 'No', name: 'No' },
        ]},model:{value:(_vm.dataForm.formalPresentation),callback:function ($$v) {_vm.$set(_vm.dataForm, "formalPresentation", $$v)},expression:"dataForm.formalPresentation"}})],1),(_vm.dataForm.formalPresentation === 'No')?_c('div',{staticClass:"mb-2"},[_c('BaseTextarea',{attrs:{"styles":"mt-0","type":"text","id":"descriptionOfReason","label":"Explicación de las razones por las cuales no se formulo la recomendación a la EV.","maxlength":"500"},model:{value:(_vm.dataForm.descriptionOfReason),callback:function ($$v) {_vm.$set(_vm.dataForm, "descriptionOfReason", $$v)},expression:"dataForm.descriptionOfReason"}})],1):_vm._e(),(_vm.dataForm.formalPresentation === 'Si')?_c('div',{staticClass:"mb-2"},[_c('BaseInput',{attrs:{"type":"date","label":"Fecha de formulación de la recomendación a la EV","placeholder":""},model:{value:(_vm.dataForm.formalPresentationDate),callback:function ($$v) {_vm.$set(_vm.dataForm, "formalPresentationDate", $$v)},expression:"dataForm.formalPresentationDate"}})],1):_vm._e(),(_vm.dataForm.formalPresentation === 'No')?_c('div',{staticClass:"mb-2 customInput"},[_c('BaseInput',{attrs:{"type":"date","label":"Fecha de creación de la recomendación","placeholder":""},model:{value:(_vm.dataForm.formalPresentationDateRecommended),callback:function ($$v) {_vm.$set(_vm.dataForm, "formalPresentationDateRecommended", $$v)},expression:"dataForm.formalPresentationDateRecommended"}})],1):_vm._e(),(
        _vm.dataForm.formalPresentation === 'No' ||
        _vm.dataForm.formalPresentation === 'Si'
      )?_c('div',{staticClass:"mb-2"},[_c('input',{ref:"fileInput",staticClass:"d-none",attrs:{"accept":".pdf,.jpg,.jpeg,.doc,.xls,.bmp,.mp3,.mp4,.msg","multiple":"","id":"hiddenFileInput","type":"file"},on:{"change":_vm.handleFileChange}}),_c('button',{staticClass:"containerFileOptional",on:{"click":_vm.openFileInput}},[(_vm.files.length === 0)?_c('div',{staticClass:"p-2"},[_vm._v(" SELECCIONAR ARCHIVO DE SOPORTE ")]):_c('div',{staticClass:"p-2"},[_vm._v("Archivos Cargados "+_vm._s(_vm.files.length))]),_c('span',{staticClass:"text-gray-500 text-xs"},[_vm._v("archivos soportados")]),_c('span',{staticClass:"mb-2"},[_vm._v("pdf, jpg, doc, xls, bmp, mp3, mp4, msg")]),_c('span',[_vm._v("Máximo 5 archivos")]),_c('span',[_vm._v("Peso máximo de soporte 1 MB")])])]):_vm._e()]),_c('div',{staticClass:"form-group w-full mt-5 text-left flex flex-col customButtons"},[_c('div',{staticClass:"flex flex-row gap-3"},[_c('a-button',{attrs:{"type":"primary","shape":"round","block":true,"ghost":""},on:{"click":_vm.backStep}},[_vm._v("Volver")]),_c('a-button',{attrs:{"type":"primary","shape":"round","block":true},on:{"click":_vm.saveRecommendation}},[_vm._v("Cargar Recomendación")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"text-blue text-left customTitle"},[_c('b',[_vm._v("Paso 2 de 2")])])
}]

export { render, staticRenderFns }