<template>
  <div class="w-full mb-2 simpleCard">
    <a-button
      v-if="visibleButtonSaveTemp"
      class="btn-save-temporaly"
      shape="round"
      type="primary"
      @click="saveTemporaly"
      >{{ msgButtonTemp }}</a-button
    >
    <h2 class="text-blue text-left customTitle">
      <b>Paso 1 de 2</b>
    </h2>
    <Accordion header="1. Caracterización de la recomendación" open>
      <div class="mb-2">
        <h3 class="text-left text-gray-500 font-bold mb-1">
          1.1 Tema general (Evento o hallazgo)
        </h3>
        <BaseTextarea
          styles="mt-0"
          type="text"
          id="generalTheme"
          label="Descripción de los hechos o situaciones que puedan afectar a los consumidores financieros"
          v-model="dataForm.generalTheme"
          maxlength="1000"
        />
      </div>
      <div class="mb-2">
        <h3 class="text-left text-gray-500 font-bold">1.2 Causa raíz</h3>
        <BaseTextarea
          styles="mt-0"
          type="text"
          id="rootCause"
          label="Describa cual es la causa u origen del hecho o situación que pueda afectar a los consumidores financieros de la entidad y que motiva la formulación de la recomendación o vocería (por favor no incluya casos individuales)."
          v-model="dataForm.rootCause"
          maxlength="1000"
        />
      </div>

      <div class="mb-2">
        <h3 class="text-left text-gray-500 font-bold">1.3 Tipología</h3>
        <Accordion header="Producto y motivo" advanceStyles="pt-0 d-flex" open>
          <div class="w-full w-50 p-2">
            <BaseSelect
              ref="products"
              id="products"
              label="Productos"
              :selectOptions="[
                { id: 1, name: 'option A' },
                { id: 2, name: 'option B' },
              ]"
              v-model="dataForm.products"
            />
          </div>
          <div class="w-full w-50 p-2">
            <BaseSelect
              ref="reason"
              id="reason"
              label="Motivos"
              :selectOptions="[
                { id: 1, name: 'option A' },
                { id: 2, name: 'option B' },
              ]"
              v-model="dataForm.reason"
            />
          </div>
        </Accordion>
      </div>
      <div class="mb-2">
        <h3 class="text-left text-gray-500 font-bold mb-1">1.4 Fuente</h3>
        <p
          class="w-full text-left mb-2 md:text-xs text-gray-500 text-opacity-80"
        >
          <b
            >Indicar si la DCF conoció o identifico el hecho o situación que
            pueda afectar a los consumidores financieros de la entidad por
            alguno o algunos de los siguientes items:</b
          >
        </p>
        <div class="mb-2 ps-2 customInput">
          <h3 class="text-left text-gray-500 font-bold mb-1">1.4.1 Vocerías</h3>
          <BaseTextarea
            styles="mt-0"
            type="text"
            id="generalTheme"
            label="Descripción de los hechos o situaciones que puedan afectar a los consumidores financieros"
            v-model="dataForm.sourceSpokespersons"
            maxlength="1000"
          />
          <BaseInput
            id="numberOfRelatedCases"
            ref="numberOfRelatedCases"
            type="text"
            label="Numero de casos relacionados con el hecho o situación."
            placeholder=""
            :maxlength="100"
            v-model="dataForm.generalThemeNumberOfRelatedCases"
          />
        </div>
        <div class="mb-2 ps-2 customInput">
          <h3 class="text-left text-gray-500 font-bold mb-1">1.4.2 Quejas</h3>
          <BaseTextarea
            styles="mt-0"
            type="text"
            id="complaints"
            label="Descripción general de los hechos o situaciones que puedan afectar a los consumidores financieros de la entidad identificados en las quejas que dan origen a la recomendación que formule el DCF."
            v-model="dataForm.sourceSpokespersons"
            maxlength="1000"
          />
          <BaseInput
            id="numberOfRelatedCases"
            ref="numberOfRelatedCases"
            type="text"
            label="Numero de casos relacionados con el hecho o situación."
            placeholder=""
            :maxlength="100"
            v-model="dataForm.complaintsNumberOfRelatedCases"
          />
        </div>
        <div class="mb-2 ps-2 customInput">
          <h3 class="text-left text-gray-500 font-bold mb-1">
            1.4.3. Practicas o Clausulas (Si la DCF no tiene identificadas
            practicas ni clausulas abusivas, indebidas, ilegales, inseguras u
            otras contrarias a la normatividad o las buenas practicas por favor
            pase a el numeral "1.4.4. Otra").
          </h3>
          <p
            class="w-full text-left mb-2 md:text-xs text-gray-500 text-opacity-80"
          >
            <b
              >Relación de aquellos contratos de adhesion u otro documento
              masivo de revision. Identifique los formatos documentos
              especificos analizados (si aplica).</b
            >
          </p>
          <div
            class="mb-1 d-flex w-100"
            v-for="(report, index) in dataForm.reports"
            v-bind:key="index"
          >
            <div :class="index > 0 ? 'w-90' : 'w-100'">
              <Accordion header="Reporte de practicas o clausulas" open>
                <div class="w-full w-50 p-2">
                  <BaseSelect
                    ref="isClausesOrPractices"
                    id="isClausesOrPractices"
                    label="Indique si la DCF encontró clausulas o practicas"
                    :selectOptions="[
                      { id: 'Practica', name: 'Practica' },
                      { id: 'Clausula', name: 'Clausula' },
                    ]"
                    v-model="dataForm.reports[index].isClausesOrPractices"
                  />
                </div>
                <div class="w-full w-50 p-2">
                  <BaseInput
                    id="dcfClausesOrPractices"
                    ref="dcfClausesOrPractices"
                    type="text"
                    label="Numero de practicas o clausulas indebidas, ilegales, inseguras, abusivas u otras (contrarias a la norma) encontradas."
                    placeholder=""
                    :maxlength="100"
                    v-model="dataForm.reports[index].dcfClausesOrPractices"
                  />
                </div>
                <div class="w-full w-50 p-2">
                  <BaseSelect
                    ref="indicateClausesOrPractices"
                    id="indicateClausesOrPractices"
                    label="Indique si se trata de clausulas o practicas i) indebidas, ii) ilegales, iii) inseguras, iv) abusivas u v) otras contrarias a la normativa o buenas practicas"
                    :selectOptions="[
                      { id: 'Option 1', name: 'Option 1' },
                      { id: 'Option 2', name: 'Option 2' },
                    ]"
                    v-model="dataForm.reports[index].indicateClausesOrPractices"
                  />
                </div>
                <div class="w-full w-50 p-2">
                  <BaseInput
                    id="numberOfContractOrDocument"
                    ref="numberOfContractOrDocument"
                    type="text"
                    label="Nombre del contrato o documento (si aplica)"
                    placeholder=""
                    :maxlength="100"
                    v-model="dataForm.reports[index].numberOfContractOrDocument"
                  />
                </div>
                <div class="w-full w-50 p-2">
                  <BaseTextarea
                    styles="mt-0"
                    type="text"
                    id="identificationAndTranscriptionOfClause"
                    label="Identificación y transcripción de la clausula o aparte respectivo (si aplica)"
                    v-model="
                      dataForm.reports[index]
                        .identificationAndTranscriptionOfClause
                    "
                    maxlength="1000"
                  />
                </div>
                <h3 class="text-left text-gray-500 font-bold mb-1">
                  Concepto de la DCF
                </h3>
                <div class="w-full w-50 p-2">
                  <BaseTextarea
                    styles="mt-0"
                    type="text"
                    id="situationPresented"
                    label="Hechos o situación presentada"
                    v-model="dataForm.reports[index].situationPresented"
                    maxlength="500"
                  />
                </div>
                <div class="w-full w-50 p-2">
                  <BaseTextarea
                    styles="mt-0"
                    type="text"
                    id="legalSource"
                    label="Fuente legal"
                    v-model="dataForm.reports[index].legalSource"
                    maxlength="200"
                  />
                </div>
                <div class="w-full w-50 p-2">
                  <BaseTextarea
                    styles="mt-0"
                    type="text"
                    id="summaryOfConsiderations"
                    label="Resumen de las consideraciones de la DCF"
                    v-model="dataForm.reports[index].summaryOfConsiderations"
                    maxlength="2000"
                  />
                </div>
              </Accordion>
            </div>
            <div v-if="index > 0" class="buttonRemove">
              <a-button
                icon="delete"
                shape="round"
                :block="true"
                @click="
                  () => {
                    changeReports(false, index);
                  }
                "
              >
              </a-button>
            </div>
          </div>
        </div>
        <div class="mb-2 ps-2 customInput">
          <a-button
            class="flex items-start justify-start align-center items-center"
            shape="round"
            :block="true"
            @click="changeReports"
          >
            <span
              class="d-flex items-start justify-start align-center items-center font-bold"
            >
              <a-icon
                type="plus"
                :style="{
                  fontSize: '20px',
                  background: '#1890ff',
                  borderRadius: '25px',
                  padding: '5px',
                  color: 'white',
                }"
                class="font-normal mr-2"
              />
              <b>Nuevo reporte de practica o clausula</b>
            </span>
          </a-button>
        </div>
        <div class="mb-2 ps-2 customInput">
          <h3 class="text-left text-gray-500 font-bold mb-1">1.4.4. Otra</h3>
          <BaseInput
            id="other"
            ref="other"
            type="text"
            label="Nombrar otra fuente, por ejemplo, noticia, red social o hecho, que genere la recomendación, distintas a las anteriores"
            placeholder=""
            :maxlength="50"
            v-model="dataForm.other"
          />
        </div>
      </div>
    </Accordion>
    <div class="form-group w-full mt-5 text-left flex flex-col customButtons">
      <div class="flex flex-row gap-3">
        <a-button
          type="primary"
          shape="round"
          :block="true"
          ghost
          @click="backStep"
          >Volver</a-button
        >
        <a-button
          type="primary"
          shape="round"
          :block="true"
          @click="saveAndNextStep"
          >Siguiente</a-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
export default {
  components: { Accordion },
  props: ["initialData"],
  data() {
    return {
      dataForm: {
        ...this.initialData,
        reports: [{}],
      },
      visibleButtonSaveTemp: true,
      msgButtonTemp: "GUARDAR COMO BORRADOR",
    };
  },
  methods: {
    saveTemporaly() {
      this.msgButtonTemp = "Guardado!";
      setTimeout(() => {
        this.visibleButtonSaveTemp = false;
      }, 2000);
      localStorage.setItem(
        "newRecommendationFormSave",
        JSON.stringify(this.dataForm)
      );
    },
    changeReports(addReport = true, index) {
      console.log({
        addReport,
        index,
      });
      if (addReport) {
        this.dataForm.reports.push({});
      } else {
        let arrayPivot = [...this.dataForm.reports];
        arrayPivot.splice(index, 1);
        this.dataForm.reports = arrayPivot;
      }
    },
    resetTargetEntity() {
      this.dataForm.targetEntity = null;
    },
    saveAndNextStep() {
      this.$emit("saveChanges", {
        ...this.initialData,
        ...this.dataForm,
      });
    },
    backStep() {
      this.$emit("backStep");
    },
  },
};
</script>

<style>
.d-flex {
  display: flex !important;
}
.simpleCard {
  margin-right: auto;
  max-width: 1020px;
  border: 1px solid #ddd6d6;
  border-radius: 10px;
  padding: 30px;
}
.w-100 {
  width: 100%;
}
.w-90 {
  width: 90%;
}
.w-50 {
  width: 50%;
}
.customTitle {
  font-size: 1.5em;
}
.customButtons {
  width: 80%;
  margin-left: 10%;
}
.ps-2 {
  padding-left: 2em;
}
.mt-0 {
  margin-top: 0px !important;
}
.mb-2 {
  margin-bottom: 2em;
}
.customInput label {
  margin-bottom: 1em !important;
}
.w-auto {
  width: auto;
  min-width: 80%;
}
.buttonRemove {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-save-temporaly {
  position: fixed;
  right: 40px;
  top: 200px;
  z-index: 10000;
}
</style>
