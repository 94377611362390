<template>
  <div class="w-full mb-2 simpleCard">
    <a-button
      v-if="visibleButtonSaveTemp"
      class="btn-save-temporaly"
      shape="round"
      type="primary"
      @click="saveTemporaly"
      >{{ msgButtonTemp }}</a-button
    >
    <h2 class="text-blue text-left customTitle">
      <b>Paso 2 de 2</b>
    </h2>
    <Accordion header="2. Formulación de la recomendación del DCF" open>
      <div class="mb-2">
        <BaseTextarea
          styles="mt-0"
          type="text"
          id="formulationOfTheDcfRecommendation"
          label="Descripción especifica de la recomendación formulada, tendiente a prevenir el impacto de los hechos o situaciones que puedan afectar a los consumidores financieros de la EV, propendiendo por el trato justo en el marco de la adecuada prestación del servicio y seguridad en el desarrollo de las actividades y en las relaciones entre esta y sus clientes, usuarios o potenciales clientes."
          v-model="dataForm.formulationOfTheDcfRecommendation"
          maxlength="1000"
        />
      </div>
      <div class="w-full w-50 mb-2">
        <BaseRadio
          name="formalPresentation"
          id="formalPresentation"
          label="¿La DCF presento formalmente la recomendación a la EV a traves de los canales establecidos?"
          :radioOptions="[
            { id: 'Si', name: 'Si' },
            { id: 'No', name: 'No' },
          ]"
          v-model="dataForm.formalPresentation"
        />
      </div>
      <div v-if="dataForm.formalPresentation === 'No'" class="mb-2">
        <BaseTextarea
          styles="mt-0"
          type="text"
          id="descriptionOfReason"
          label="Explicación de las razones por las cuales no se formulo la recomendación a la EV."
          v-model="dataForm.descriptionOfReason"
          maxlength="500"
        />
      </div>
      <div v-if="dataForm.formalPresentation === 'Si'" class="mb-2">
        <BaseInput
          type="date"
          label="Fecha de formulación de la recomendación a la EV"
          placeholder=""
          v-model="dataForm.formalPresentationDate"
        />
      </div>
      <div v-if="dataForm.formalPresentation === 'No'" class="mb-2 customInput">
        <BaseInput
          type="date"
          label="Fecha de creación de la recomendación"
          placeholder=""
          v-model="dataForm.formalPresentationDateRecommended"
        />
      </div>
      <div
        v-if="
          dataForm.formalPresentation === 'No' ||
          dataForm.formalPresentation === 'Si'
        "
        class="mb-2"
      >
        <input
          ref="fileInput"
          accept=".pdf,.jpg,.jpeg,.doc,.xls,.bmp,.mp3,.mp4,.msg"
          multiple
          id="hiddenFileInput"
          type="file"
          class="d-none"
          @change="handleFileChange"
        />
        <button @click="openFileInput" class="containerFileOptional">
          <div v-if="files.length === 0" class="p-2">
            SELECCIONAR ARCHIVO DE SOPORTE
          </div>
          <div v-else class="p-2">Archivos Cargados {{ files.length }}</div>
          <span class="text-gray-500 text-xs">archivos soportados</span>
          <span class="mb-2">pdf, jpg, doc, xls, bmp, mp3, mp4, msg</span>
          <span>Máximo 5 archivos</span>
          <span>Peso máximo de soporte 1 MB</span>
        </button>
      </div>
    </Accordion>
    <div class="form-group w-full mt-5 text-left flex flex-col customButtons">
      <div class="flex flex-row gap-3">
        <a-button
          type="primary"
          shape="round"
          :block="true"
          ghost
          @click="backStep"
          >Volver</a-button
        >
        <a-button
          type="primary"
          shape="round"
          :block="true"
          @click="saveRecommendation"
          >Cargar Recomendación</a-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
export default {
  components: { Accordion },
  props: ["initialData"],
  data() {
    return {
      dataForm: {
        ...this.initialData,
        formalPresentationDateRecommended: this.currentDate(),
      },
      files: [],
      visibleButtonSaveTemp: true,
      msgButtonTemp: "GUARDAR COMO BORRADOR",
    };
  },
  methods: {
    currentDate() {
      // Obtener la fecha actual
      const today = new Date();

      // Obtener los componentes de la fecha
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11, por lo que se suma 1
      const day = String(today.getDate()).padStart(2, "0");

      // Crear la cadena en el formato deseado
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const files = event.target.files;

      if (files.length > 5) {
        alert("Selecciona máximo 5 archivos.");
        return;
      }

      this.files = files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.size > 1024 * 1024) {
          alert(
            "El archivo " +
              file.name +
              " excede el tamaño máximo permitido (1 MB)."
          );
          return;
        }

        console.log("Archivo seleccionado:", file);
      }
    },
    saveRecommendation() {
      this.$emit("saveRecommendation", {
        ...this.initialData,
        ...this.dataForm,
      });
    },
    saveTemporaly() {
      this.msgButtonTemp = "Guardado!";
      setTimeout(() => {
        this.visibleButtonSaveTemp = false;
      }, 2000);
      localStorage.setItem(
        "newRecommendationFormSave",
        JSON.stringify(this.dataForm)
      );
    },
    backStep() {
      this.$emit("backStep");
    },
  },
};
</script>

<style>
.d-flex {
  display: flex !important;
}
.simpleCard {
  margin-right: auto;
  max-width: 1020px;
  border: 1px solid #ddd6d6;
  border-radius: 10px;
  padding: 30px;
}
.w-100 {
  width: 100%;
}
.w-90 {
  width: 90%;
}
.w-50 {
  width: 50%;
}
.customTitle {
  font-size: 1.5em;
}
.customButtons {
  width: 80%;
  margin-left: 10%;
}
.ps-2 {
  padding-left: 2em;
}
.mt-0 {
  margin-top: 0px !important;
}
.mb-2 {
  margin-bottom: 2em;
}
.customInput label {
  margin-bottom: 1em !important;
}
.w-auto {
  width: auto;
  min-width: 80%;
}
.buttonRemove {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.d-none {
  display: none;
}
.containerFileOptional {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed gray;
  padding: 20px;
  border-radius: 15px;
}
.containerFileOptional div {
  border-radius: 15px;
  background: rgba(51, 102, 204, var(--tw-bg-opacity));
  margin-bottom: 10px;
  color: white;
  padding: 0.5em 4em;
}
.btn-save-temporaly {
  position: fixed;
  right: 40px;
  top: 200px;
  z-index: 10000;
}
</style>
